import React, { ReactElement, useContext, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CreateRoom from "../features/CreateRoom";
import Room from "../features/Room";
import { Switch, Route, useParams } from "react-router-dom";
import { PlayerContext } from "../contexts/PlayerContext";
import JoinRoom from "../features/JoinRoom";
import { SessionStorage } from "../localstorage/SessionStorage";
import { RoomParams } from "../types/RouterTypes";
import { UserMenu } from "./UserMenu";
import About from "../main/About";
import AboutButton from "../components/AboutButton";
import SignUp from "../auth/SignUp";
import SignIn from "../auth/SignIn";
import RecentRooms from "../features/RecentRooms";
import ResetPassword from "../auth/ResetPassword";
import SubscriptionRibbon from "../features/SubscriptionRibbon";
import { AuthState, UserContext } from "../contexts/UserContext";
import { ManageSubscription } from "../features/ManageSubscription";
import { CardDeckSettings } from "../features/CardDeckSettings";
import { CardDeckSettingsDialog } from "../features/CardDeckSettingsDialog";
import { Subscribe } from "../features/Subscribe";
import { SingInDialog } from "../auth/SignInDialog";
import { RequireAuth } from "../auth/RequireAuth";
import { usePlayer } from "../contexts/usePlayer";
import { SignUpDialog } from "../auth/SignUpDialog";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

export default function AppContent(): ReactElement {
  const classes = useStyles();
  const { authState } = useContext(UserContext);
  const { player } = usePlayer();
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false);
  const [isSignUpDialogOpen, setIsSignUpDialogOpen] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar position="sticky" enableColorOnDark color="primary">
        <SubscriptionRibbon />
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Story Point Poker
          </Typography>
          {!(player || authState == AuthState.SignedIn) && <AboutButton />}
          <UserMenu
            player={player}
            onSettingsClicked={() => setIsSettingsDialogOpen(true)}
            onSignInClicked={() => setIsSignInDialogOpen(true)}
            onSignUpClicked={() => setIsSignUpDialogOpen(true)}
            onSignOutClicked={() => null}
            onManageSubscriptionClicked={() => null}
          />
        </Toolbar>
      </AppBar>

      <Switch>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/resetpassword">
          <ResetPassword email="" />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/subscription">
          <RequireAuth>
            <ManageSubscription />
          </RequireAuth>
        </Route>
        <Route path="/subscribe">
          <RequireAuth>
            <Subscribe />
          </RequireAuth>
        </Route>
        <Route path="/:id/settings">
          <CardDeckSettings />
        </Route>
        <Route path="/:id">
          <RoomWrapper />
          {isSettingsDialogOpen && <CardDeckSettingsDialog open={isSettingsDialogOpen} onClosed={() => setIsSettingsDialogOpen(false)} />}
        </Route>
        <Route path="/">
          <CreateRoomWrapper />
        </Route>
      </Switch>
      {isSignInDialogOpen && <SingInDialog open={isSignInDialogOpen} onClosed={() => setIsSignInDialogOpen(false)} />}
      {isSignUpDialogOpen && <SignUpDialog open={isSignUpDialogOpen} onClosed={() => setIsSignUpDialogOpen(false)} />}
    </div>
  );
}

//useParams only works for child components. this is why RoomContentWrapper is extracted from App
function RoomWrapper(): ReactElement {
  const { id: roomUrlId } = useParams<RoomParams>();
  const { player, setPlayer } = useContext(PlayerContext);
  const session = new SessionStorage().GetSession(roomUrlId);

  useEffect(() => {
    if (!player) {
      //console.log('update playerContext from local storage session after hard refresh')
      if (session) {
        //console.log('setPlayer from local storage(' + session.userId + ',' + session.userName + ');');
        setPlayer(session.userId, session.userName, false);
      }
    }
  }, []);

  return session ? <Room roomUrlId={roomUrlId} /> : <JoinRoom roomUrlId={roomUrlId} />;
}

function CreateRoomWrapper(): ReactElement {
  const { authState } = useContext(UserContext);

  return (
    <>
      <CreateRoom />
      {authState == AuthState.SignedIn && <RecentRooms />}
    </>
  );
}
