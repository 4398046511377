import React, { useState, ReactElement } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { StoryPoint } from "../types/StoryPoint";

const useStyles = makeStyles(() =>
    createStyles({
        aspectRatioWrapper: {
            width: "100%",
            paddingTop: "150%", //css hack to preserve aspect ratio
            position: "relative",
        },
    })
);

interface CardProps {
    storyPoint: StoryPoint;
    selected: boolean;
    onSelected?: (storyPoint?: StoryPoint) => void;
}

export default function PlayingCard(props: CardProps): ReactElement {
    const [elevation, setElevation] = useState(0);
    const classes = useStyles();

    function handleMouseUp() {
        if (props.onSelected) {
            if (props.selected) {
                props.onSelected(undefined);
            } else {
                props.onSelected(props.storyPoint);
            }
        }
    }

    return (
        <div className={classes.aspectRatioWrapper}>
            <Paper
                sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "0",
                    bottom: "0",
                    right: "0",
                    left: "0",
                    color: props.selected ? "primary.contrastText" : "text.secondary",
                    bgcolor: props.selected ? "secondary.main" : "background.paper",

                    transition: "transform 0.2s" /* Animation */,
                    "&:hover": {
                        transform: "scale(1.05)",
                    },
                    "&:active": {
                        color: "primary.contrastText",
                        bgcolor: "secondary.light",
                    },
                }}
                elevation={elevation}
                onMouseOver={() => setElevation(3)}
                onMouseLeave={() => setElevation(0)}
                onMouseUp={handleMouseUp}
            >
                <Typography variant="h3">{props.storyPoint}</Typography>
            </Paper>
        </div>
    );
}
