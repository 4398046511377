import { ReactElement, useContext } from "react";
import { AuthState, UserContext } from "../contexts/UserContext";
import SignIn from "./SignIn";
import React from "react";
import { useHistory } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProtectedRouteProps {}



export function RequireAuth(props: React.PropsWithChildren<ProtectedRouteProps>): ReactElement  {
  const { authState } = useContext(UserContext);
  const history = useHistory();

  const handleResetPasswordRequest = () => {
    history.push('/resetpassword');
  };

  if (authState != AuthState.SignedIn) {
    // user is not authenticated
    return <SignIn onResetPasswordRequest={handleResetPasswordRequest} />;
  }
  return <>{props.children}</>;
}