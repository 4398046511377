import React, { ReactElement, useContext, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import { GameState } from './Room';
import { Timelapse, NotInterested, HourglassEmpty, WifiOff, Visibility } from '@mui/icons-material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import Lottie from "lottie-react";
import reconnectAnimLight from '../assets/lottie/reconnect-light.json';
import reconnectAnimDark from '../assets/lottie/reconnect-dark.json';
import { usePlayer } from '../contexts/usePlayer';
import { PlayerState, PlayerViewModel } from '../viewModels/PlayerViewModel';
import { PlayerMenu } from '../components/PlayerMenu';
import { WindowGridContainer } from '../components/WindowGridContainer';
import { PlayerContextProps } from '../contexts/PlayerContext';
import { AuthState, UserContext } from '../contexts/UserContext';
import { RenamePlayerDialog } from '../features/RenamePlayerDialog';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		avatar: {
			width: theme.spacing(3.2),
			height: theme.spacing(3.2),
		},
		itemAvatar: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		itemPlayer: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
		},
		itemStatus: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		itemModerationMenu: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		moderatorIcon: {
			marginLeft: theme.spacing(1),
			color: theme.palette.icon.main,
		},
		observerIcon: {
			marginLeft: theme.spacing(0),
			color: theme.palette.icon.main,
		},
		icon: {
			color: theme.palette.icon.main,
		},
		textPrimary: {
			color: theme.palette.text.primary,
		},
		textDisabled: {
			color: theme.palette.text.disabled,
		}
	}),
);

interface PlayerListProps {
	gameState: GameState;
	players: PlayerViewModel[];
	roomUrlId: string;
	onPlayerKicked: (player: PlayerViewModel) => void;
	onPlayerToggledObserving: (player: PlayerViewModel) => void;
	onPlayerLeftRoom: (player: PlayerViewModel) => void;
}

export default function PlayerList(props: PlayerListProps): ReactElement {
	const classes = useStyles();
	const contextPlayer = usePlayer();
	const { authState } = useContext(UserContext);
	let rowKey = 0;
	const theme = useTheme();
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	function handlePlayerKicked(player: PlayerViewModel) {
		//console.log("kick player: " + player.id);
		props.onPlayerKicked(player);
	}

	function handlePlayerToggleObserving(player: PlayerViewModel) {
		props.onPlayerToggledObserving(player);
	}

	function handleRenameClicked() {
		setIsDialogOpen(true);
	}

	function handlePlayerLeftRoom(player: PlayerViewModel) {
		//console.log("player leaving. Id: " + player.id);
		props.onPlayerLeftRoom(player);
	}

	function renderPlayer(player: PlayerViewModel) {
		return <>
			<Typography className={player.state === PlayerState.Active ? classes.textPrimary : classes.textDisabled} variant="body1">{player.name}</Typography>
			{player.isModerator && (<Tooltip title={player.name + " is moderator of this room"} aria-label="moderator"><SportsEsportsIcon className={classes.moderatorIcon} /></Tooltip>)}
		</>
	}

	function renderStatus(gameState: GameState, player: PlayerViewModel) {
		if (player.state === PlayerState.ConnectionProblem) {
			return (<Tooltip title="Connection problem! Trying to reconnect..." aria-label="connection problem">
				<Lottie style={{ width: 24 }} animationData={theme.palette.mode === 'light' ? reconnectAnimLight : reconnectAnimDark} />
			</Tooltip>);
		} else if (player.state === PlayerState.Inactive) {
			return (<Tooltip title="Inactive and has no connection to the room anymore" aria-label="inactive">
				<WifiOff color="disabled" />
			</Tooltip>);
		}

		if (player.isObserver) {
			return (<Tooltip title={player.name + " is just observing this game"} aria-label="observing_and_waiting"><Visibility className={classes.observerIcon} /></Tooltip>);
		}

		switch (gameState) {
			case GameState.WaitingForPLayers:
				return (<Tooltip title="Waiting for the game to start" aria-label="waiting"><HourglassEmpty className={classes.icon} /></Tooltip>);
			case GameState.Playing:
				return player.hasVoted() ?
					(<Tooltip title="Estimate given" aria-label="estimate given"><CheckCircleRoundedIcon className={classes.icon} /></Tooltip>)
					: (<Tooltip title="Not decided on an estimate yet" aria-label="player deciding"><Timelapse className={classes.icon} /></Tooltip>);
			case GameState.Result:
				return player.storyPoint ? <Typography color="textPrimary" variant="body1">{player.storyPoint}</Typography> : <NotInterested className={classes.icon} />
			default:
				return null;
		}
	}

	function renderPlayerMenu(player: PlayerViewModel, contextPlayer: PlayerContextProps): React.ReactNode {
		const showKickPlayer: boolean = (
			authState == AuthState.SignedIn &&
			contextPlayer.player?.isModerator == true &&
			contextPlayer.player?.id !== player.id);

		const showRenamePlayer: boolean = contextPlayer.player?.id == player.id;

		const showLeaveRoom: boolean = contextPlayer.player?.id == player.id;

		if (showLeaveRoom || showKickPlayer) {
			return <PlayerMenu
				player={player}
				showKickPlayer={showKickPlayer}
				showRenamePlayer={showRenamePlayer}
				showLeaveRoom={showLeaveRoom}
				onPlayerKicked={(player) => { handlePlayerKicked(player); }}
				onPlayerToggleObserving={(player) => { handlePlayerToggleObserving(player); }}
				onRenameClicked={() => { handleRenameClicked(); }}
				onPlayerLeftRoom={(player) => { handlePlayerLeftRoom(player); }}
			/>;
		} else {
			return <></>;
		}
	}

	return (
		<WindowGridContainer title="Players">
			{props.gameState === GameState.Loading ?
				<Grid item xs={12}> <Skeleton />
					<Skeleton animation={false} />
					<Skeleton animation="wave" />
				</Grid> :
				props.players.sort((a,b) => a.name.localeCompare(b.name)).map(player => 
					[
						<Grid key={rowKey++} item xs={2} sm={3} lg={2} className={classes.itemAvatar}>
							<img className={classes.avatar} src={player.currentAvatarSrc()} alt="" />
							<link rel="preload" as="image" href={player.inactiveAvatarSrc()}></link>
						</Grid>,
						<Grid key={rowKey++} item xs={8} sm={7} lg={8} className={classes.itemPlayer}>{renderPlayer(player)}</Grid>,
						<Grid key={rowKey++} item xs={1} className={classes.itemStatus}>{renderStatus(props.gameState, player)}</Grid>,
						<Grid key={rowKey++} item xs={1} className={classes.itemModerationMenu}>{renderPlayerMenu(player, contextPlayer)}</Grid>,
					]
				)
			}
			{isDialogOpen && <RenamePlayerDialog roomUrlId={props.roomUrlId} open={isDialogOpen} onClosed={() => setIsDialogOpen(false)}></RenamePlayerDialog>}
		</WindowGridContainer>
	);
}