import { useContext } from "react";
import { PlayerContext } from "../contexts/PlayerContext";
import { Session, SessionStorage } from "../localstorage/SessionStorage";
import { PlayerStorage } from "../localstorage/PlayerStorage";
import { Action, Category, Tracking } from "../util/Tracking";
import { useHistory } from "react-router-dom";

export function useJoinRoom() {
    const { setPlayer } = useContext(PlayerContext);
    const sessionStorage = new SessionStorage();
    const playerStorage = new PlayerStorage();
    const history = useHistory();
    const track = new Tracking();

      const joinRoom = async (urlId: string, playerId: string, playerName: string, isModerator: boolean) => {  
        track.event(Category.Room, Action.RoomJoined);
        sessionStorage.SaveSession(new Session(urlId, playerId, playerName));
        setPlayer(playerId, playerName, isModerator);
        playerStorage.SaveOriginalPlayerId(playerId);
        history.push("/" + urlId);
      }
  
      return [joinRoom];
  }