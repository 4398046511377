import React, { ReactElement, useContext, useState } from "react";
import { Theme } from '@mui/material/styles';
import { IconButton, ListItemIcon, Menu, MenuItem, Typography, FormControlLabel, Switch, ListItemText } from "@mui/material";
import { MoreVert, ExitToApp, Edit, SportsEsports, Visibility, AlignHorizontalRight } from "@mui/icons-material";
import { Tracking, Action, Category } from "../util/Tracking";
import { PlayerViewModel } from "../viewModels/PlayerViewModel";
import { UserContext } from "../contexts/UserContext";
import { UserStatus } from "../api/models/User";
import { usePlayer } from "../contexts/usePlayer";

interface PlayerMenuProps {
    player: PlayerViewModel;
    showKickPlayer: boolean;
    showRenamePlayer: boolean;
    showLeaveRoom: boolean;
    onPlayerKicked: (player: PlayerViewModel) => void;
    onPlayerToggleObserving: (player: PlayerViewModel) => void;
    onRenameClicked: () => void;
    onPlayerLeftRoom: (player: PlayerViewModel) => void;
}

export function PlayerMenu(props: PlayerMenuProps): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const track = new Tracking();
    const { setPlayer, player: contextPlayer } = usePlayer();
    const [ playerIsObserver, setPlayerIsObserver ] = useState(props.player.isObserver);
    const { user } = useContext(UserContext);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleModerationToggled = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleCloseMenu();
        const isModerating = event.target.checked;
        if (contextPlayer) {
            setPlayer(contextPlayer.id, contextPlayer.name, isModerating);
        }
        isModerating 
            ? track.event(Category.Moderation, Action.ModerationStarted)
            : track.event(Category.Moderation, Action.ModerationStopped);
    };

    const toggleObserving = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleCloseMenu();
        const isObserving = event.target.checked;
        setPlayerIsObserver(isObserving);
        props.player.isObserver = isObserving;

        props.onPlayerToggleObserving(props.player);

        isObserving
            ? track.event(Category.Moderation, Action.PlayerStartedObserving)
            : track.event(Category.Moderation, Action.PlayerStoppedObserving);
    };

    const handleKickClicked = () => {
        handleCloseMenu();
        props.onPlayerKicked(props.player);
        track.event(Category.Moderation, Action.ModerationPlayerKicked);
    };

    const handleRenameClicked = () => {
        handleCloseMenu();
        props.onRenameClicked();
        track.event(Category.Room, Action.PlayerRenamed);
    };

    const handleLeaveRoomClicked = () => {
        handleCloseMenu();
        props.onPlayerLeftRoom(props.player);
        track.event(Category.Room, Action.PlayerLeftRoom);
    };

    return (
        <>
            <IconButton
                onClick={handleOpenMenu}
                size="small"
                aria-controls={open ? "player-moderation-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
            >
                <MoreVert />
            </IconButton>

            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                onClose={handleCloseMenu}
            >
                {props.player.id == contextPlayer?.id && (
                    <MenuItem>
                        <ListItemIcon>
                            <SportsEsports fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Is Moderator</ListItemText>
                        <Switch onChange={handleModerationToggled} value="playerIsObserver" checked={contextPlayer.isModerator} color="secondary" size="small"/>
                    </MenuItem>
                )}

                {props.player.id == contextPlayer?.id && (
                    <MenuItem>
                        <ListItemIcon>
                            <Visibility fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Is Observer</ListItemText>
                        <Switch onChange={toggleObserving} value="playerIsObserver" checked={playerIsObserver} color="secondary" size="small" />
                    </MenuItem>
                )}

                {props.showKickPlayer && (
                    <MenuItem disabled={user?.status == UserStatus.Suspended} onClick={handleKickClicked}>
                        <ListItemIcon>
                            <ExitToApp fontSize="small" />
                        </ListItemIcon>
                        <Typography color="textPrimary" variant="body1" align="left">
                            Kick Player
                        </Typography>
                    </MenuItem>
                )}

                {props.showRenamePlayer && (
                    <MenuItem disabled={user?.status == UserStatus.Suspended} onClick={handleRenameClicked}>
                        <ListItemIcon>
                            <Edit fontSize="small" />
                        </ListItemIcon>
                        <Typography color="textPrimary" variant="body1" align="left">
                            Rename Player
                        </Typography>
                    </MenuItem>
                )}

                {props.showLeaveRoom && (
                    <MenuItem onClick={handleLeaveRoomClicked}>
                        <ListItemIcon>
                            <ExitToApp fontSize="small" />
                        </ListItemIcon>
                        <Typography color="textPrimary" variant="body1" align="left">
                            Leave Room
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}
