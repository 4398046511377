import { PlayingCardDTO } from "../api/DTO";
import { CardDeckType } from "../api/Requests";

export class AppSettingsStorage {
    private DEFAULT_CARD_DECK_TYPE = "default_card_deck_type";
    private DEFAULT_PLAYING_CARDS_KEY = "default_playing_cards";

    public SaveDefaultCardDeckType(cardDeckType: CardDeckType): void {
        window.localStorage.setItem(this.DEFAULT_CARD_DECK_TYPE, JSON.stringify(cardDeckType));
    }

    public SaveDefaultPlayingCards(playingCards: PlayingCardDTO[]): void {
        window.localStorage.setItem(this.DEFAULT_PLAYING_CARDS_KEY, JSON.stringify(playingCards));
    }

    public GetDefaultCardDeckType(): CardDeckType | null {
        const item = window.localStorage.getItem(this.DEFAULT_CARD_DECK_TYPE);
        return item ? (JSON.parse(item) as CardDeckType) : null;
    }

    public GetDefaultPlayingCards(): PlayingCardDTO[] | null {
        const item = window.localStorage.getItem(this.DEFAULT_PLAYING_CARDS_KEY);
        return item ? (JSON.parse(item) as PlayingCardDTO[]) : null;
    }
}
