import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { WindowGridContainer } from "../components/WindowGridContainer";
import React from "react";
import { Grid, Theme, Typography } from "@mui/material";
import { GameState } from "./Room";
import { HttpClient } from "../api/HttpClient";
import { useAlert } from "../contexts/alert/useAlert";
import { CardDeckType, GameInsightsResponse } from "../api/Requests";
import moment, { Moment } from "moment";
import { UserContext } from "../contexts/UserContext";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingLeft: theme.spacing(1),
        },
        itemTitle: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        titleWaiting: {
            padding: theme.spacing(1),
        },
        itemTitleWaiting: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        itemValue: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        itemModerationMenu: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        moderatorIcon: {
            marginLeft: theme.spacing(1),
            color: theme.palette.icon.main,
        },
        icon: {
            color: theme.palette.icon.main,
        },
        textPrimary: {
            color: theme.palette.text.primary,
        },
        textDisabled: {
            color: theme.palette.text.disabled,
        },
    })
);

interface GameInsightsProps {
    gameState: GameState;
    cardDeckType?: CardDeckType;
    roomUrlId: string;
}

export default function GameInsights(props: GameInsightsProps): ReactElement {
    const classes = useStyles();
    const alert = useAlert();
    const { authToken } = useContext(UserContext);
    const [, setIsLoading] = useState(false);
    const [averagePoints, setAveragePoints] = useState("");
    const [averageCard, setAverageCard] = useState("");
    const [timerText, setTimerText] = useState("This round started a few seconds ago.");
    let startMoment: Moment;
    const timerId = useRef<NodeJS.Timeout>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const httpClient = new HttpClient(authToken);
                const response = (await httpClient.get(`/rooms/${props.roomUrlId}/insights`)) as GameInsightsResponse;

                if (response.averagePoints != null) {
                    setAveragePoints(response.averagePoints.toString());
                }

                setAverageCard(response.averageCard);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                alert.showMessage(
                    "Sorry, but we could not fetch moderator insights 😔. Please contact support@storypoint.poker if this keeps happening.",
                    "error"
                );
            }
        };

        if (props.gameState == GameState.Result) {
            setAverageCard("");
            setAveragePoints("");
            fetchData();
        }

        if (props.gameState == GameState.Playing) {
            if (timerId.current) {
                clearInterval(timerId.current);
            }
            //TODO pri2: persist startTime as a part of the game data and use that instead to make it more robust. This would not survive a hard refresh
            startMoment = moment();
            timerId.current = setInterval(() => {
                setTimerText("This round started " + startMoment.fromNow() + ".");
            }, 1000);
        }
    }, [props.gameState]);

    function renderByGameState(state: GameState) {
        switch (state) {
            case GameState.Result:
                return (
                    <>
                        {props.cardDeckType != CardDeckType.TShirt && (
                            <>
                                <Grid item xs={10} className={classes.itemTitle}>
                                    <Typography variant="body1" className={classes.title}>
                                        Average Points
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.itemValue}>
                                    <Typography variant="body1">{averagePoints}</Typography>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={10} className={classes.itemTitle}>
                            <Typography variant="body1" className={classes.title}>
                                Average Card
                            </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.itemValue}>
                            <Typography variant="body1">{averageCard}</Typography>
                        </Grid>
                    </>
                );
            case GameState.Playing:
                return (
                    <>
                        <Grid item xs={12} className={classes.itemTitle}>
                            <Typography variant="body1" className={classes.title}>
                                {timerText}
                            </Typography>
                        </Grid>
                    </>
                );

            case GameState.WaitingForPLayers:
                return (
                    <>
                        <Grid item xs={12} className={classes.itemTitleWaiting}>
                            <Typography variant="body1" align="center" className={classes.titleWaiting}>
                                We will try to bring you valuable insights to help you make good decisions. Please contact us at support@storypoint.poker if
                                you miss anything. Have a nice session 👋
                            </Typography>
                        </Grid>
                    </>
                );
            case GameState.Suspended:
                return (
                    <>
                        <Grid item xs={12} className={classes.itemTitleWaiting}>
                            <Typography variant="body1" align="center" className={classes.titleWaiting}>
                                Sorry, but moderator insights is only available for registered users with an active plan 😞
                            </Typography>
                        </Grid>
                    </>
                );
        }
    }

    return <WindowGridContainer title="Moderator Insights">{renderByGameState(props.gameState)}</WindowGridContainer>;
}
