/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export type AlertSeverity = 'success' | 'info' | 'warning' | 'error';
export type AlertAction = () => void;

export interface AlertContextProps {
   /**
   * Show MuiAlert Snackbar message
   * @param {string} message message to display
   * @param {AlertSeverity} severity label for the action button
   */
  showMessage: (message: string, severity: AlertSeverity) => void;
  showMessageWithAction: (message: string, severity: AlertSeverity, actionTitle: string, action: AlertAction) => void;
}

export const AlertContext = React.createContext<AlertContextProps>({
  showMessage: (message: string, severity: AlertSeverity) => {},
  showMessageWithAction: (message: string, severity: AlertSeverity, actionTitle: string, action: AlertAction) => {}  
});