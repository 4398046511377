import React, { ReactElement, useEffect } from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { wootric: any; }
}

export interface WootricProps {
  //Required to uniquely identify a user. Email is recommended but this can be any unique identifier.
  userIdentifier: string;
  //days before wootric is first shown can be adjusted in wootric and uses createdAt to decide when 
  userCreatedAt: Date;
  //custom attributes
  isModetator: boolean;
  userName: string
}

export default function Wootric(props: WootricProps): ReactElement {
  //email: Required to uniquely identify a user. Email is recommended but this can be any unique identifier.
  //createdAt: The current logged in user's sign-up date as a Unix timestamp.
  useEffect(() => {
    const setupScript = document.createElement('script');
    setupScript.type = 'text/javascript';
    setupScript.id = 'wootric-settings';
    setupScript.async = true;
    setupScript.innerHTML = `
      wootric_no_surveyed_cookie = ${process.env.REACT_APP_WOOTRIC_DEBUG_MODE_ENABLED};
      wootric_survey_immediately = ${process.env.REACT_APP_WOOTRIC_DEBUG_MODE_ENABLED};
      window.wootricSettings = {
        email: '${props.userIdentifier}',
        created_at: ${Math.floor(props.userCreatedAt.getTime()/1000)},
        account_token: '${process.env.REACT_APP_WOOTRIC_ACCOUNT_TOKEN}',
        modal_theme: 'light',
        modal_footprint: 'compact',
        properties:{
          user_name:'${props.userName}',
          user_role:'${props.isModetator ? "Moderator" : "Participant"}',
        }
      };
    `;
    if (document.getElementById('wootric-settings') == null) {
      document.body.appendChild(setupScript);
    }

    // Beacon
    const beacon = document.createElement('script');
    beacon.type = 'text/javascript';
    beacon.id = 'wootric-beacon';
    beacon.async = true;

    beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
    beacon.onload = function () {
      window.wootric('run');
    };
    if (document.getElementById('wootric-beacon') == null) {
      document.body.appendChild(beacon)
    }
  }, []);

  return (
    <div />
  );
}