/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { User } from '../api/models/User';

export enum AuthState {
  Unknown = "unknown",
  Initializing = "initializing",
  Authenticating = "authenticating", 
  SignedIn = "signed_in",
  SignedOut = "signed_out",
}

export interface UserContextProps {
  authToken?: string;
  authState: AuthState;
  user?: User;
  setAuthToken: (authToken?: string) => void;
  setAuthState: (authState: AuthState) => void;
  setUser: (user?: User) => void;
}

export const UserContext = React.createContext<UserContextProps>({
    authToken: undefined,
    authState: AuthState.Unknown,
    user: undefined,
    setAuthToken: (authToken?: string) => {},
    setAuthState: (authState: AuthState) => {},
    setUser: (user?: User) => {}
});