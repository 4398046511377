import React, { useContext } from "react";
import { ReactElement, useEffect, useRef } from "react";
import { UserContext } from "../contexts/UserContext";

export function ManageSubscription(): ReactElement {
  const { user } = useContext(UserContext);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (user) {
      formRef.current && formRef.current.submit();
    }
  }, [user, formRef]);

  return user?.plan?.subscription? (
    <form ref={formRef} action="api/create-portal-session" method="POST">
      <input type="hidden" id="customer-id" name="customer_id" value={user.plan.subscription.customerId} />
    </form>
  ) : (
    <></>
  );
}
