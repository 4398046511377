import React, { ReactElement } from "react";
import { CustomDialog } from "../components/CustomDialog";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";

export interface SignInDialogProps {
  open: boolean;
  onClosed: () => void;
}

enum SignInPage {
  SignIn,
  ResetPassword,
}

export function SingInDialog(props: SignInDialogProps): ReactElement {
  const [open, setOpen] = React.useState(props.open);
  const [page, setPage] = React.useState<SignInPage>(SignInPage.SignIn);
  const [email, setEmail] = React.useState("");

  const reset = () => {
    setEmail("");
    setPage(SignInPage.SignIn);
  };

  const handleClosed = () => {
    reset();
    props.onClosed();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResetPasswordRequest = (email: string) => {
    setEmail(email);
    setPage(SignInPage.ResetPassword);
  };

  return (
    <CustomDialog open={open} onClosed={handleClosed}>
      {page == SignInPage.SignIn ? <SignIn onSignInCompleted={handleClose} onResetPasswordRequest={handleResetPasswordRequest} /> : <ResetPassword email={email} onResetPasswordCompleted={handleClose} />}
    </CustomDialog>
  );
}
