export const firebaseConfigProd = {
    apiKey: "AIzaSyDaponrY15YPfDaMa5QMxBQ3sb313dh6K0",
    authDomain: "story-point-poker-c55ea.firebaseapp.com",
    projectId: "story-point-poker-c55ea",
    storageBucket: "story-point-poker-c55ea.appspot.com",
    messagingSenderId: "1025889072061",
    appId: "1:1025889072061:web:297798a0ad4a03a16958d4",
    measurementId: "G-E1PFB949TE",
  };

  export const firebaseConfigDev = {
    apiKey: "AIzaSyBjqTcUwyS6uwdfiD_CQn2Ekh6VM_Cbb0g",
    authDomain: "story-point-poker-dev.firebaseapp.com",
    projectId: "story-point-poker-dev",
    storageBucket: "story-point-poker-dev.appspot.com",
    messagingSenderId: "426850479351",
    appId: "1:426850479351:web:7fe02eefe5f10158cef7f2"
  };