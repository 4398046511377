import { Button, SvgIcon } from '@mui/material';
import React, { ReactElement } from 'react';
import { ReactComponent as GoogleLogo } from "../assets/svg/google.svg";


interface GoogleButtonProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled: boolean;
}

export function GoogleButton(props: GoogleButtonProps): ReactElement {
    
    return (
        <Button color='inherit'
        disabled={props.disabled}
        size="small"
        onClick={props.onClick}
        startIcon={
          <SvgIcon>
            <GoogleLogo />
          </SvgIcon>
        }
      >
        {" "}
        Google
      </Button>
    );
}
