import React, { ReactElement, useEffect, useState } from "react";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { ContextPlayer, PlayerContext } from "../contexts/PlayerContext";
import { lightTheme } from "../themes/lightTheme";
import { darkTheme } from "../themes/darkTheme";
import { AlertProvider } from "../contexts/alert/AlertProvider";
import { Tracking } from "../util/Tracking";
import { CssBaseline, StyledEngineProvider, useMediaQuery } from "@mui/material";
import Elmahio from "elmah.io.javascript";
import { AuthProvider } from "../auth/AuthProvider";
import AppContent from "./AppContent";

export default function App(): ReactElement {
    const [player, setPlayer] = useState<ContextPlayer>();

    const track = new Tracking();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    function setContextPlayer(id: string, name: string, isModerator: boolean) {
        setPlayer(new ContextPlayer(id, name, isModerator));
    }

    useEffect(() => {
        const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_UNIVERSAL_TRACKING_ID;
        if (trackingId) {
            track.initialize(trackingId);
        }

        const apiKey = process.env.REACT_APP_ELMAHIO_API_KEY;
        const logId = process.env.REACT_APP_ELMAHIO_LOG_ID;
        if (apiKey && logId) {
            new Elmahio({ apiKey: apiKey, logId: logId, breadcrumbs: true });
        }
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={responsiveFontSizes(prefersDarkMode ? darkTheme : lightTheme)}>
                <CssBaseline />
                <PlayerContext.Provider value={{ player: player, setPlayer: setContextPlayer }}>
                    <AuthProvider>
                        <AlertProvider>
                            <Router>
                                <AppContent />
                            </Router>
                        </AlertProvider>
                    </AuthProvider>
                </PlayerContext.Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
