import React, { ReactElement } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function AboutButton(): ReactElement {
  const history = useHistory();
  return (
    <Tooltip title="Information about StoryPoint.Poker" aria-label="About">
      <IconButton
        onClick={() => history.push('/about')}
        color="inherit"
      >
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
}