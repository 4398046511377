import { Button, Snackbar, SnackbarCloseReason, Theme } from "@mui/material";
import MuiAlert from "@mui/lab/Alert";
import React, { ReactElement, useRef } from "react";
import { AlertAction, AlertContext, AlertSeverity } from "./AlertContext";
import { createStyles, makeStyles } from "@mui/styles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AlertProviderProps {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.text.primary,
            minWidth: 80,
        },
    })
);

export function AlertProvider(props: React.PropsWithChildren<AlertProviderProps>): ReactElement {
    const [isSnackBarShown, setIsSnackBarShown] = React.useState(false);
    const [severity, setSeverity] = React.useState<AlertSeverity>("success");
    const [message, setMessage] = React.useState("");
    const [actionButtonTitle, setActionButtonTitle] = React.useState("");
    const onActionButtonClicked = useRef<AlertAction>();
    const classes = useStyles();

    const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsSnackBarShown(false);
        setMessage("");
    };

    function showAlert(message: string, severity: AlertSeverity) {
        setActionButtonTitle("");
        onActionButtonClicked.current = undefined;
        setMessage(message);
        setSeverity(severity);
        setIsSnackBarShown(true);
    }

    function showAlertWithAction(message: string, severity: AlertSeverity, actionTitle: string, action: AlertAction) {
        showAlert(message, severity);

        setActionButtonTitle(actionTitle);
        onActionButtonClicked.current = action;
    }

    const action = (
        <Button
            className={classes.button}
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
                if (onActionButtonClicked.current) {
                    onActionButtonClicked.current();
                }
            }}
        >
            {actionButtonTitle}
        </Button>
    );

    return (
        <AlertContext.Provider value={{ showMessage: showAlert, showMessageWithAction: showAlertWithAction }}>
            {props.children}

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={isSnackBarShown}
                autoHideDuration={severity === "error" ? 10000 : 7000}
                onClose={handleCloseSnackbar}
            >
                <MuiAlert
                    sx={{ color: "success.contrastText" }}
                    elevation={6}
                    variant="filled"
                    onClose={(event) => handleCloseSnackbar(event)}
                    severity={severity}
                    action={actionButtonTitle.length > 0 ? action : null}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        </AlertContext.Provider>
    );
}
