import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import { Button, Theme, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        container: {
            padding: theme.spacing(3),
            maxWidth: 1200,
        },
        heading: {
            marginBottom: theme.spacing(1),
        }
    }),
);

export default function About(): ReactElement {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.outerContainer}>
            <Grid xs={12} sm={10} md={8} container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography align="center" variant="h5">“Storypoint.poker is a simple online planning poker app that will speed up estimation in remote planning sessions“</Typography>
                    <br />
                </Grid>
                <Grid item>
                    <Typography className={classes.heading} align="center" variant="h5">Why another planning poker tool?</Typography>
                    <Typography align="left" variant="body1">“Does the world really need yet another planning poker tool?”, you might ask. I’m not sure yet, but after trying out a bunch of different tools I gave up, because I had trouble finding something simple and yet flexible enough for my own need and taste. So I gave it a shot my self and I’ll let you decide whether it has something new to offer.</Typography>
                </Grid>

                <Grid item>
                    <Typography className={classes.heading} align="center" variant="h5">Is this tool for me?</Typography>
                    <Typography align="left" variant="body1">
                        Since March 2020 all of our planning meetings are now remote (you all know why 😞). I’m normally the facilitator sharing the backlog on my screen. We go through all stories one by one, and when it comes to estimation I just need a dead simple tool to help orchestra the planning poker game without applying a lot of rules.
                        <ul>
                            <li>I don’t want to enter stories into the planning poker tool, because we are already looking at the backlog on my shared screen. </li>
                            <li>As a facilitator I want to be able to reveal estimates whenever I want. Maybe a team member is away for coffee or I have an observer on board. I’m not always a part of estimation either so I like to be in control and take the decision to reveal estimates whenever I want. </li>
                            <li>I want to be able to hand over moderation without starting a new game in cases where I have to leave.</li>
                            <li>I want tool to have a responsive layout that can fit the screen of all devices. Also be able to take up as little room on my desktop as possible in situations where I want to show the backlog in another window side by side with the estimation tool. </li>
                            <li>At last I think it is important that a planning poker tool do not take up too much attention. It is a secondary tool in the planning process so I think it should behave as discreetly as possible.</li>
                        </ul>

                    So for those of you who have moved to remote planning sessions and are now maybe entering storypoints in your team chat or are using a tool that should be simpler I hope this can be a little improvement in the planning process that should speed up things, hopefully add an enjoyable experience or at least make the life a little easier for the facilitator 🙂
                    <br /><br />
                    I hope you will try it out and tell me what you think 🤞
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography className={classes.heading} align="center" variant="h5">Key Selling Points</Typography>
                    <Typography align="left" variant="body1">
                    <ul>
                        <li>No account or login requirements</li>
                        <li>Easy to get started. Create a planning poker room in seconds and share the room with your teammates through a unique link</li>
                        <li>The moderator is always in control, not the tool. As an example you are able to reveal team estimates whenever you want</li>
                        <li>You are able to hand over moderation to other players or even have multiple moderators at once if you want</li>
                        <li>Responsive layout so that it fits on every screen. Even small mobile phones and large desktop screens.</li>
                        <li>No need to enter user stories or task information. You already have that in your daily task management system.</li>
                        <li>Free plan available</li>
                    </ul>
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" size="large" color="primary" onClick={()=>history.push('/')}>Get Started</Button>
                </Grid>
                <br/><br/>
            </Grid>
        </div>
    );
}