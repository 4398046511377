import React, { ReactElement } from "react";
import SignUp from "./SignUp";
import { CustomDialog } from "../components/CustomDialog";

export interface SignUpDialogProps {
  open: boolean;
  onClosed: () => void;
  onCompleted?: () => void;
}
//TODO pri2: should animate after signup. look at RenamePlayerDialog
export function SignUpDialog(props: SignUpDialogProps): ReactElement {
  const [open, setOpen] = React.useState(props.open);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosed = () => {
    props.onClosed();
  };

  const handleCompleted = () => {
    if (props.onCompleted) {
        props.onCompleted();
      }
      handleClose();
  };

  return (
    <CustomDialog open={open} onClosed={handleClosed}>
      <SignUp onSignUpCompleted={handleCompleted} />
    </CustomDialog>
  );
}
