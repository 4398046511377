import React, { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import { Typography, Link, IconButton, Tooltip, Theme } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAlert } from "../contexts/alert/useAlert";
import { createStyles, makeStyles } from "@mui/styles";
import { GameState } from "./Room";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
        },
        container: {
            marginBottom: theme.spacing(3),
            maxWidth: 600,
            [theme.breakpoints.up("sm")]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
        },
        text: {
            maxWidth: 500,
        },
        icon: {
            color: theme.palette.icon.main,
        },
        link: {
            color: theme.palette.link.main,
        },
    })
);

interface PlayerMessagingProps {
    state: GameState;
    isModerator: boolean;
}

export default function GameStateMessage(props: PlayerMessagingProps): ReactElement {
    const classes = useStyles();
    const roomUrl = window.location.href;
    const alert = useAlert();

    function renderWaiting() {
        return (
            <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center" wrap="nowrap">
                <Grid item className={classes.text}>
                    <Typography align="center" variant="h5">
                        {props.isModerator ? "Invite players to the room" : "Wait for the moderator to start the session"}
                    </Typography>
                    <Typography align="center" variant="subtitle1">
                        Send the link below to your team mates and soon they will start joining the room
                    </Typography>
                </Grid>
                <Grid item>
                    <Link className={classes.link} variant="body1">
                        {roomUrl}
                    </Link>
                    <CopyToClipboard text={roomUrl} onCopy={() => alert.showMessage("Room link is now copied to the clipboard 👍", "success")}>
                        <Tooltip title="Copy Room Link" aria-label="Copy Room Link">
                            <IconButton className={classes.icon}>
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                </Grid>
                {props.isModerator ? (
                    <Grid item className={classes.text}>
                        <Typography variant="body2" align="center">
                            You are the moderator and are in control of the estimation session. Press &quot;Start&quot; when all players has joined the room 🚀.
                        </Typography>
                    </Grid>
                ) : (
                    <Grid item className={classes.text}>
                        <Typography variant="body2" align="center">
                            When the moderator starts the game your planning poker card deck will appear and the estimation session can begin. In the meanwhile
                            this is the perfect time to go grap a cup of coffee ☕
                        </Typography>
                    </Grid>
                )}
            </Grid>
        );
    }
    function renderSuspended() {
        return (
            <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center" wrap="nowrap">
                <Grid item className={classes.text}>
                    <Typography align="center" variant="h5">
                        I hope you have enjoyed the free trial 🙏
                    </Typography>
                    <Typography align="center" variant="subtitle1">
                        But for now your room is suspended 😞
                    </Typography>
                </Grid>
                <Grid item>
                    <Link className={classes.link} variant="body1">
                        {roomUrl}
                    </Link>
                    <CopyToClipboard text={roomUrl} onCopy={() => alert.showMessage("Room link is now copied to the clipboard 👍", "success")}>
                        <Tooltip title="Copy Room Link" aria-label="Copy Room Link">
                            <IconButton className={classes.icon}>
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                </Grid>
                <Grid item className={classes.text}>
                    <Typography variant="body1" align="center">
                        In order to reactivate this room one of you needs to have an active account. So please consider the{" "}
                        <Link underline="always" target="_blank" href="https://storypoint.poker/#prices" color="inherit">
                            Business Subscription
                        </Link>
                        . If you have any questions feel free to contact us at support@storypoint.poker.
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            {props.state == GameState.WaitingForPLayers && renderWaiting()}
            {props.state == GameState.Suspended && renderSuspended()}
        </div>
    );
}
