import { Button, Grid, Theme, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ReactElement } from "react";
import { PlanStatus, PlanType } from "../api/models/User";
import { useAlert } from "../contexts/alert/useAlert";
import { UserContext } from "../contexts/UserContext";
import { Action, Category, Tracking } from "../util/Tracking";
import { SubscribeDialog } from "./SubscribeDialog";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      background: theme.palette.error.main,
    },
    textItem: {
      display: "flex",
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      justifyContent: "flex-start",
      alignItems: "center",
    },
    buttonItem: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  })
);

export interface SubscriptionRibbonProps {
  sessionId?: string;
}

export default function SubscriptionRibbon(props: SubscriptionRibbonProps): ReactElement {
  const classes = useStyles();
  const alert = useAlert();
  const { user } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [ribbonMessage, setRibbonMessage] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const track = new Tracking();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      //TODO pri2: tracking for all callbacks are not working because GA isn't initialized. UseEffect of childcomponents is called before parents so the hole way of tracking has to be refactored. maybe with a shared context and hooks. but GA Universal is on its way out so maybe it has to go together with thinking about the future of event tracking in generel.
      track.event(Category.User, Action.PurchaseCompleted, user?.idPId);
      alert.showMessage("Thank you for your purchase, it means a lot to us 🙏", "success");
    }

    if (query.get("canceled")) {
      track.event(Category.User, Action.PurchaseCanceled, user?.idPId);
      alert.showMessage("your purhcase is canceled, please contact us if you have any questions", "warning");
    }
  }, []);

  useEffect(() => {
    let visible = false;
    if (user) {
      const plan = user.plan;
      const isPremiumCanceled = plan.type == PlanType.Premium && plan.status == PlanStatus.Canceled;
      const isTrial = plan.type == PlanType.Trial;
      let message = "";
      if (isTrial) {
        message =
          user.plan.daysLeft > 0

            ? "There is " + user.plan.daysLeft + " days left of your free trial. We hope that you like it 🙏"
            : "Your free trial has ended and your account is deactivated until you pay for the business subscription.";
        visible = true;
        if (user.plan.daysLeft <= 0) {
          track.event(Category.User, Action.TrialEnded, user.idPId);
        }
      } else if (isPremiumCanceled) {
        message = "You subscription has been canceled and your account is deactivated. If you choose to pay for the subscription your account will be reactivated.";
        visible = true;
      }
      setRibbonMessage(message);
    }
    setIsVisible(visible);
  }, [user]);

  return isVisible ? (
    <Grid container className={classes.container} spacing={0} direction="row">
      <Grid item xs={8} className={classes.textItem}>
        <Typography color="inherit" variant="body2">
          {ribbonMessage}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.buttonItem}>
        <Button size="small" id="checkout-and-portal-button" type="submit" color="inherit" onClick={() => setIsDialogOpen(true)}>
          Subscribe Now
        </Button>
        {isDialogOpen && <SubscribeDialog open={isDialogOpen} onClosed={() => setIsDialogOpen(false)} />}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}
