import React, { useState, SyntheticEvent, ReactElement, useContext } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Button, Theme, Typography } from "@mui/material";
import { useAlert } from "../contexts/alert/useAlert";
import { LoadingButton } from "../components/LoadingButton";
import { EmailValidationType, InputValidatorMode } from "../contexts/useInputValidator";
import { Auth, deleteUser, GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, signInWithPopup, UserCredential } from "firebase/auth";
import { HttpClient, NotFoundError } from "../api/HttpClient";
import { AuthErrorCode } from "./AuthErrorCodes";
import { AuthProviderType, useOnSignInCompleted, useOnSignInFailed, useOnSignInStarted, useSignOut } from "./useAuthentication";
import { Action, Category, Tracking } from "../util/Tracking";
import { AuthState, UserContext } from "../contexts/UserContext";
import { User } from "../api/models/User";
import { GoogleButton } from "../components/GoogleButton";
import { WindowsButton } from "../components/WindowsButton";
import { PasswordField } from "../components/PasswordField";
import { EmailField } from "../components/EmailField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
  })
);

export interface SignInProps {
  onSignInCompleted?: () => void;
  onResetPasswordRequest?: (email: string) => void;
}

export default function SignIn(props: SignInProps): ReactElement {
  const track = new Tracking();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [inputValidationMode, setInputValidationMode] = useState(InputValidatorMode.onTyping);
  const classes = useStyles();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const { authState } = useContext(UserContext);
  const [isResetPasswordButtonVisible, setIsResetPasswordButtonVisible] = useState(false);
  const [signOut] = useSignOut();
  const [onSignInStarted] = useOnSignInStarted();
  const [onSignInCompleted] = useOnSignInCompleted();
  const [onSignInFailed] = useOnSignInFailed();


  function handleOnChangeEmail(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setInputValidationMode(InputValidatorMode.onTyping);
    setEmail(event.target.value);
  }

  function handleOnChangePassword(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setInputValidationMode(InputValidatorMode.onTyping);
    setPassword(event.target.value);
  }

  async function handleSignOut() {
    signOut();
  }

  function handleSignInStarted(): Auth {
    track.event(Category.User, Action.SignInStarted);
    setIsLoading(true);
    return onSignInStarted();
  }

  function reset() {
    setInputValidationMode(InputValidatorMode.onTyping);
    setEmail("");
    setPassword("");
  }

  function handleSignInCompleted(authToken: string, user: User) {
    track.event(Category.User, Action.SignInCompleted);
    onSignInCompleted(authToken, user);
    if (props.onSignInCompleted) {
      reset();
      props.onSignInCompleted(); 
    }
    setIsLoading(false);
  }

  function handleSignInFailed(error?: any) {
    setIsLoading(false);
    if (error != null) {
      let errorMessage = "Sorry, but we are not able to sign you in, please try again 🤞";
      console.error("errorcode: " + error.code + ", errorMessage: '" + error.message + "'");
      if (error.code == AuthErrorCode.INVALID_PASSWORD) {
        setIsResetPasswordButtonVisible(true);
        errorMessage = "Wrong password, please try again or reset your password 🤞";
      } else if (error.code == AuthErrorCode.USER_NOT_FOUND) {
        errorMessage = "No user found with that email, please try again or consider signing up 🤞";
      } else if (error.code == AuthErrorCode.ACCOUNT_EXISTS_WITH_DIFFERENT_PROVIDER) {
        errorMessage = "You need to sign in with the provider used when signing up. Please try again 🤞";
      }
      alert.showMessage(errorMessage, "error");
    }
    onSignInFailed();
  }

  async function signIn(userCredential: UserCredential) {
    const firebaseUser = userCredential.user;
    const jwt = await firebaseUser.getIdToken();
    const httpClient = new HttpClient(jwt);

    try {
      const user = (await httpClient.post("/users/signin")) as User;
      alert.showMessage("You are now signed in 👍", "success");
      handleSignInCompleted(jwt, user);
    } catch (e) {
      handleSignInFailed(null);

      //rollback user in the case where the user tried to sign in with a third party provider but user was not created first
      if (e instanceof NotFoundError) {
        deleteUser(firebaseUser)
          .then(() => {
            //console.log("user rolled back");
          })
          .catch((error) => {
            console.error("not able to rollback user in firebase: + " + error);
          });
      }

      const errorMessage = e instanceof NotFoundError ? "No user found with that email, please try again or consider signing up 🤞" : "Sorry, an unexpected error occured during sign in, please try again or contact support 🤞";
      alert.showMessage(errorMessage, "error");
    }
  }

  async function handleEmailPasswordSignIn(event: SyntheticEvent) {
    event.preventDefault();
    setInputValidationMode(InputValidatorMode.onSubmit);

    if (!isEmailValid || !isPasswordValid) {
      return;
    }

    const auth = handleSignInStarted();

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        signIn(userCredential);
      })
      .catch((error) => {
        handleSignInFailed(error);
      });
  }

  async function handleProviderSignIn(event: SyntheticEvent, providerType: AuthProviderType) {
    event.preventDefault();

    const auth = handleSignInStarted();

    let provider: any;

    if (providerType == AuthProviderType.Windows) {
      provider = new OAuthProvider("microsoft.com");
      provider.setCustomParameters({
        prompt: "select_account",
      });
    } else if (providerType == AuthProviderType.Google) {
      provider = new GoogleAuthProvider();
    }

    signInWithPopup(auth, provider)
      .then(async (userCredential) => {
        signIn(userCredential);
      })
      .catch((error) => {
        handleSignInFailed(error);
      });
  }

  function handleResetPasswordRequest(event: SyntheticEvent) {
    event.preventDefault();
    if (props.onResetPasswordRequest != null) {
      props.onResetPasswordRequest(email);
    }
  }

  return (
    <>
      {authState == AuthState.SignedIn ? (
        <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography align="center" variant="h5">
              You are signed in
            </Typography>
            <Typography align="center" variant="subtitle1">
              and have moderator powers
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleSignOut}>Sign Out</Button>
          </Grid>
        </Grid>
      ) : authState == AuthState.SignedOut || authState == AuthState.Authenticating ? (
        <form onSubmit={handleEmailPasswordSignIn}>
          <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography align="center" variant="h5">
                Sign In
              </Typography>
              <Typography align="center" variant="subtitle1">
                and gain moderator powers
              </Typography>
            </Grid>

            <Grid item>
              <EmailField disabled={isLoading} onChange={(event) => handleOnChangeEmail(event)} onValidationChanged={(isValid) => setIsEmailValid(isValid)} validationMode={inputValidationMode} validationType={EmailValidationType.Other} />
            </Grid>
            <Grid item>
              <PasswordField disabled={isLoading} onChange={(event) => handleOnChangePassword(event)} onValidationChanged={(isValid) => setIsPasswordValid(isValid)} validationMode={inputValidationMode} />
            </Grid>

            <Grid item>
              <LoadingButton type="submit" isLoading={isLoading}>Sign In</LoadingButton>
            </Grid>

            {isResetPasswordButtonVisible && (
              <Grid item>
                <Button onClick={handleResetPasswordRequest} size="large" variant="outlined" color="secondary">
                  Reset Password
                </Button>
              </Grid>
            )}

            <Grid>
              <Typography variant="caption">or</Typography>
            </Grid>
            <Grid container spacing={0} direction="column" alignItems="center">
              <Grid item>
                <WindowsButton disabled={isLoading} onClick={(event) => handleProviderSignIn(event, AuthProviderType.Windows)} />
              </Grid>
              <Grid item>
                <GoogleButton disabled={isLoading} onClick={(event) => handleProviderSignIn(event, AuthProviderType.Google)} />
              </Grid>
            </Grid>
          </Grid>
        </form>
      ) : (
        <></>
      )}
    </>
  );
}
