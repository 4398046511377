import React, { ReactElement } from "react";
import { Subscribe } from "./Subscribe";
import { CustomDialog } from "../components/CustomDialog";

export interface SubscribeDialogProps {
  open: boolean;
  onClosed: () => void;
}

export function SubscribeDialog(props: SubscribeDialogProps): ReactElement {
  const [open, setOpen] = React.useState(props.open);

  const handleClosed = () => {
    props.onClosed();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomDialog open={open} onClosed={handleClosed}>
      <Subscribe onCompleted={handleClose} />
    </CustomDialog>
  );
}
