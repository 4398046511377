
export class PlayerStorage {
    private ORIGINAL_PLAYER_ID_KEY = "original_user_id";
    private ORIGINAL_PLAYER_CREATED_AT_KEY = "original_user_created_at"

    public SaveOriginalPlayerId (id: string): void {
        const currentId = this.GetOriginalPlayerId();
        //console.log("current original_user_id:" + currentId);
        if (currentId === null) {
            window.localStorage.setItem(this.ORIGINAL_PLAYER_ID_KEY, JSON.stringify(id));
            //console.log('original_user_id saved: ' + id);
            this.SaveOriginalPlayerCreatedAt(new Date());
        } else {
            //console.error("original_user_id is not empty and cannot be overridden") 
        }
    }

    public GetOriginalPlayerId (): string | null {
        const item = window.localStorage.getItem(this.ORIGINAL_PLAYER_ID_KEY);
        return item ? JSON.parse(item) as string : null;
    }

    private SaveOriginalPlayerCreatedAt (createAt: Date) {
        window.localStorage.setItem(this.ORIGINAL_PLAYER_CREATED_AT_KEY, JSON.stringify(createAt));
        //console.log('original_user_created_at saved: ' + createAt);
    }

    public GetOriginalPlayerCreatedAt (): Date | null {
        const item = window.localStorage.getItem(this.ORIGINAL_PLAYER_CREATED_AT_KEY);
        return item ? new Date(JSON.parse(item)) : null;
    }
}