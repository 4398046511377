import {Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      background: theme.palette.background.paper,
      marginBottom: theme.spacing(3),
      borderRadius: 5,
      overflow: "hidden",
      },
    header: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: theme.palette.primary.main,
      padding: theme.spacing(0.6),
    },
    itemContent: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    footer: {},
    h6: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    }
  })
);

export interface WindowGridContainerProps {
    title: string;
  }
  
  export function WindowGridContainer(props: React.PropsWithChildren<WindowGridContainerProps>): ReactElement {
    const classes = useStyles();

    return (
      <Grid container className={classes.container} spacing={0} direction="row">
        <Grid className={classes.header} item xs={12}>
          <Typography className={classes.h6} variant="h6">
            {props.title}
          </Typography>
        </Grid>
  
        <Grid className={classes.itemContent} container spacing={0} alignItems="center" justifyContent="space-around">
          {props.children}
        </Grid>
  
        <Grid item xs={12} className={classes.footer}></Grid>
      </Grid>
    );
  }