import { Button, SvgIcon } from '@mui/material';
import React, { ReactElement } from 'react';
import { ReactComponent as WindowsLogo } from "../assets/svg/windows.svg";


interface WindowsButtonProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled: boolean;
}

export function WindowsButton(props: WindowsButtonProps): ReactElement {
    
    return (
        <Button color='inherit'
        disabled={props.disabled}
        size="small"
        onClick={props.onClick}
        startIcon={
            <SvgIcon>
            <WindowsLogo />
          </SvgIcon>
        }
      >
        {" "}
        Microsoft
      </Button>
    );
}
