import { Auth, getAuth } from "firebase/auth";
import { useContext } from "react";
import { User } from "../api/models/User";
import { AuthState, UserContext } from "../contexts/UserContext";
import { Action, Category, Tracking } from "../util/Tracking";

export enum AuthProviderType {
  EmailPassWord,
  Google,
  Windows,
}

//TODO pri2: ask friend if it is possible to expose there function through the same overall function useAuthentication?
export function useSignOut() {
  const { setAuthState, setAuthToken, setUser } = useContext(UserContext);
  const track = new Tracking();
  
    const signOut = async () => {
      track.event(Category.User, Action.SignOutCompleted);
      await getAuth().signOut();
      setAuthToken(undefined);
      setUser(undefined);
      setAuthState(AuthState.SignedOut);
    }

    return [signOut];
}

export function useOnSignInStarted() {
  const { setAuthState } = useContext(UserContext);

    const onSignInStarted = (): Auth => {
      const auth = getAuth();
      setAuthState(AuthState.Authenticating);
      return auth;
    }

    return [onSignInStarted];
}

export function useOnSignInCompleted() {
  const { setAuthState, setAuthToken, setUser } = useContext(UserContext);

  const onSignInCompleted = (authToken: string, user: User) => {
    setAuthToken(authToken);
    setUser(user);
    setAuthState(AuthState.SignedIn);
  }

    return [onSignInCompleted];
}

export function useOnSignInFailed() {
    const [signOut] = useSignOut();

    const onSignInFailed = () => {
      signOut();
    }

    return [onSignInFailed];
}