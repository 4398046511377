import ReactGA from 'react-ga';

export enum Category {
	Game = "Game",
	Room = "Room",
	Moderation = "Moderation",
	User = "User"
}

export enum Action {
	//Room
	GameStarted = "Game Started",
	EstimationRoundStarted = "Estimation Round Started",
	EstimationRoundCompleted = "Estimation Round Completed",
	RoomCreated = "Room Created",
	RoomJoined = "Room Joined",
	PlayingCardsUpdated = "Playing Cards Updated",
	CardDeckTypeChanged = "Card Deck Type Changed",
	ModerationStarted = "Moderation Started",
	ModerationStopped = "Moderation Stopped",
	ModerationPlayerKicked = "Player Kicked",
	PlayerRenamed = "Player Renamed",

	//User
	SignUpOpened = "SignUp Opened",
	SignUpStarted = "SignUp Started",
	SignUpCompleted = "SignUp Completed",
	SignInStarted = "SignIn Started",
	SignInCompleted = "SignIn Completed",
	ResetPasswordRequestSent = "Reset Password Request Sent",
	SignOutCompleted = "SignOut Completed",
	RecentRoomVisited = "Recent Room Visited",
	PurchaseStarted = "Purchase Started",
	PurchaseCanceled = "Purchase Canceled",
	PurchaseCompleted = "Purchase Completed",
	PurchaseLinkCreated = "Purchase Link Created",
	TrialEnded = "Trial Ended",

	//Player
	PlayerLeftRoom = "Player Left Room",
	PlayerStartedObserving = "Player Started Observing Game",
	PlayerStoppedObserving = "Player Stopped Observing Game",
}

export enum Timing {
	EstimationRoundLength = "Estimation Round Length",
}

export class Tracking {

	public initialize(trackingId: string): void {
		ReactGA.initialize(trackingId);
	}

	public event(category: Category, action: Action, label?: string, value?: number): void {
		ReactGA.event({
			category: category.toString(),
			action: action.toString(),
			label: label,
			value: value
		});
	}

	public timing(category: Category, timing: Timing, value: number, label?: string): void {
		ReactGA.timing({
			category: category.toString(),
			variable: timing.toString(),
			value: value, // in milliseconds
			label: label
		});
	}
}