import { TextField } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { EmailValidationType, InputValidatorMode, useEmailValidator } from '../contexts/useInputValidator';


interface EmailFieldProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onValidationChanged: (isValid: boolean) => void;
    validationType: EmailValidationType;
    validationMode: InputValidatorMode;
    disabled: boolean;
    label?: string;
}

export function EmailField(props: EmailFieldProps): ReactElement {
    const [emailHelperText, setEmailHelperText] = useState("");
    const [validateEmail] = useEmailValidator(props.validationType);
    const [email , setEmail] = useState("");

    useEffect(()=>{
        if (props.validationMode == InputValidatorMode.onSubmit) {
            validateInputEmail(email, props.validationMode);
        }

    },[props.validationMode]);

    function validateInputEmail(inputEmail: string, mode: InputValidatorMode) {
        const res = validateEmail(inputEmail, mode);
        setEmailHelperText(res.errorMessage);
        return res.isValid;
    }
 
    function handleOnChangeEmail(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setEmail(event.target.value);
        const res = validateEmail(event.target.value, InputValidatorMode.onSubmit);
        props.onValidationChanged(res.isValid);
        validateInputEmail(event.target.value, InputValidatorMode.onTyping);
        props.onChange(event);
      }

    return (
        <TextField
        type="email"
        error={emailHelperText.length > 0}
        helperText={emailHelperText}
        disabled={props.disabled}
        onChange={handleOnChangeEmail}
        id="email-field"
        label={props.label ? props.label : "Please enter email"}
        variant="outlined"
        color="secondary"
      />
    );
}
