import { StoryPoint } from "../types/StoryPoint";

export class PlayerViewModel {
    id: string;
    userId: string;
    name: string;
    isModerator: boolean;
    isObserver: boolean;
    storyPoint?: StoryPoint;
    state: PlayerState;

    constructor(id: string, userId: string, name: string, isModerator: boolean, isObserver: boolean, state: PlayerState, storyPoint?: StoryPoint) {
        this.id = id;
        this.userId = userId;
        this.name = name;
        this.isModerator = isModerator;
        this.isObserver = isObserver;
        this.state = state;
        this.storyPoint = storyPoint;
    }

    hasVoted(): boolean {
        return this.storyPoint != null;
    }

    currentAvatarSrc(): string {
        return this.avatarSrc(this.name, this.state);
    }

    inactiveAvatarSrc(): string {
        return this.avatarSrc(this.name, PlayerState.Inactive);
    }

    //see documentation here https://www.dicebear.com/styles/bottts/
    avatarSrc(playerName: string, playerState: PlayerState): string {
        let url = "https://api.dicebear.com/7.x/bottts/svg?seed=" + playerName;
        if (playerState !== PlayerState.Active) {
            url += "&baseColor=909090&eyes=dizzy&mouth=grill01";
        }
        return url;
    }
}

export enum PlayerState {
    Active,
    Inactive,
    ConnectionProblem
}