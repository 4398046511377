export class GameStorage {
    private LAST_ESTIMATION_ROUND_STARTED_AT = "last_estimation_round_started_at"
    private NUMBER_OF_PLAYING_CARDS = "number_of_playing_cards"

    public SaveLastEstimationRoundStartedAt (startedAt: Date): void {
        window.localStorage.setItem(this.LAST_ESTIMATION_ROUND_STARTED_AT, JSON.stringify(startedAt));
        //console.log('original_user_created_at saved: ' + createAt);
    }

    public GetLastEstimationRoundStartedAt (): Date | null {
        const item = window.localStorage.getItem(this.LAST_ESTIMATION_ROUND_STARTED_AT);
        return item ? new Date(JSON.parse(item)) : null;
    }

    public SaveNumberOfPlayingCards (numberOfPlayingCards: number): void {
        window.localStorage.setItem(this.NUMBER_OF_PLAYING_CARDS, JSON.stringify(numberOfPlayingCards));
    }

    public GetNumberOfPlayingCards (): number {
        const item = window.localStorage.getItem(this.NUMBER_OF_PLAYING_CARDS);
        return item ? JSON.parse(item) : 13;
    }
}