import React, { ReactElement } from "react";
import { CustomDialog } from "../components/CustomDialog";
import { RenamePlayer } from "./RenamePlayer";

export interface RenamePlayerDialogProps {
    roomUrlId: string;
    open: boolean;
    onClosed: () => void;
}

export function RenamePlayerDialog(props: RenamePlayerDialogProps): ReactElement {
    const [open, setOpen] = React.useState(props.open);

    const handleClosed = () => {
        props.onClosed();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <CustomDialog open={open} onClosed={handleClosed}>
            <RenamePlayer roomUrlId={props.roomUrlId} onCompleted={handleClose} />
        </CustomDialog>
    );
}
