/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export class ContextPlayer {
  id: string;
  name: string;
  isModerator: boolean;

  constructor(id: string, name: string, isModerator: boolean, jwt?: string) {
    this.id = id;
    this.name = name;
    this.isModerator = isModerator;
  }
}

export interface PlayerContextProps {
  player?: ContextPlayer;
  setPlayer: (id: string, name: string, isModerator: boolean) => void;
}

export const PlayerContext = React.createContext<PlayerContextProps>({
    player: undefined,
    setPlayer: (id: string, name: string, isModerator: boolean) => {},
});