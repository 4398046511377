import React, { useState, SyntheticEvent, ReactElement, useContext, useEffect } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Link, Theme, Typography } from "@mui/material";
import { useAlert } from "../contexts/alert/useAlert";
import { LoadingButton } from "../components/LoadingButton";
import { EmailValidationType, InputValidatorMode } from "../contexts/useInputValidator";
import { createUserWithEmailAndPassword, GoogleAuthProvider, OAuthProvider, sendEmailVerification, signInWithPopup, User as FireBaseUser } from "firebase/auth";
import { ConflictError, HttpClient } from "../api/HttpClient";
import { SignUpRequest } from "../api/Requests";
import { AuthErrorCode } from "./AuthErrorCodes";
import { AuthProviderType, useOnSignInCompleted, useOnSignInFailed, useOnSignInStarted } from "./useAuthentication";
import { Action, Category, Tracking } from "../util/Tracking";
import { AuthState, UserContext } from "../contexts/UserContext";
import { User } from "../api/models/User";
import { GoogleButton } from "../components/GoogleButton";
import { WindowsButton } from "../components/WindowsButton";
import { PasswordField } from "../components/PasswordField";
import { EmailField } from "../components/EmailField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    link: {
      maxWidth: 300,
      fontStyle: "italic",
      textAlign: "center",
    },
  })
);

export interface SignUpProps {
  onSignUpCompleted?: () => void;
}

export default function SignUp(props: SignUpProps): ReactElement {
  const track = new Tracking();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [inputValidationMode, setInputValidationMode] = useState(InputValidatorMode.onTyping);
  const classes = useStyles();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const { authState } = useContext(UserContext);
  const [onSignInStarted] = useOnSignInStarted();
  const [onSignInCompleted] = useOnSignInCompleted();
  const [onSignInFailed] = useOnSignInFailed();

  useEffect(()=>{
    document.title = "Story Point Poker | Sign Up"
},[]);

  function handleOnChangeEmail(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setInputValidationMode(InputValidatorMode.onTyping);
    setEmail(event.target.value);
  }

  function handleOnChangePassword(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setInputValidationMode(InputValidatorMode.onTyping);
    setPassword(event.target.value);
  }

  function onSignUpStarted() {
    track.event(Category.User, Action.SignUpStarted);
    setIsLoading(true);
    return onSignInStarted();
  }

  function onSignUpCompleted(authToken: string, user: User) {
    track.event(Category.User, Action.SignUpCompleted);
    onSignInCompleted(authToken, user);
    if (props.onSignUpCompleted) {
      props.onSignUpCompleted();
    }
    setIsLoading(false);
  }

  function onSignUpFailed(error?: any) {
    setIsLoading(false);
    if (error != null) {
      let errorMessage = "Not able to sign up, please try again 🤞";
      //console.error("errorcode: " + error.code + ", errorMessage: '" + error.message + "'");
      if (error.code == AuthErrorCode.EMAIL_EXISTS) {
        errorMessage = "Email already in use, either try to sign in instead or use another email 🤞";
      }
      alert.showMessage(errorMessage, "error");
    }
    onSignInFailed();
  }

  async function handleEmailPasswordSignUp(event: SyntheticEvent) {
    event.preventDefault();
    setInputValidationMode(InputValidatorMode.onSubmit);

    if (!isEmailValid || !isPasswordValid) {
      return;
    }

    const auth = onSignUpStarted();

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await signUp(userCredential.user, true);
      })
      .catch((error) => {
        onSignUpFailed(error);
      });
  }

  async function signUp(firebaseUser: FireBaseUser, mustSendEmailVerificationEmail: boolean) {
    const jwt = await firebaseUser.getIdToken();
    const httpClient = new HttpClient(jwt);

    try {
      const request = new SignUpRequest(firebaseUser.email!);
      const user = (await httpClient.post("/users/signup", request)) as User;

      if (mustSendEmailVerificationEmail) {
        sendEmailVerification(firebaseUser).then(() => {
          alert.showMessage("Your new user is created and an email verification email is sent 👍", "success");
          onSignUpCompleted(jwt, user);
        });
      } else {
        alert.showMessage("Your new user is created 👍", "success");
        onSignUpCompleted(jwt, user);
      }
    } catch (e) {
      onSignUpFailed();
      const errorMessage = e instanceof ConflictError ? "User already exists, please try to sign in instead 🤞" : "Not able to sign up, please try again 🤞";
      alert.showMessage(errorMessage, "error");
    }
  }

  async function handleProviderSignUp(event: SyntheticEvent, providerType: AuthProviderType) {
    event.preventDefault();
    const auth = onSignUpStarted();

    let provider: any;

    if (providerType == AuthProviderType.Windows) {
      provider = new OAuthProvider("microsoft.com");
      provider.setCustomParameters({
        prompt: "select_account",
      });
    } else if (providerType == AuthProviderType.Google) {
      provider = new GoogleAuthProvider();
    }

    signInWithPopup(auth, provider)
      .then(async (userCredential) => {
        await signUp(userCredential.user, false);
      })
      .catch((error) => {
        onSignUpFailed(error);
      });
  }

  return (
    <>
      {authState == AuthState.SignedIn ? (
        <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography align="center" variant="h5">
              You are registered
            </Typography>
            <Typography align="center" variant="subtitle1">
              and have moderator powers, please enjoy 🥳
            </Typography>
          </Grid>
        </Grid>
      ) : authState == AuthState.SignedOut || authState == AuthState.Authenticating ? (
        <form onSubmit={handleEmailPasswordSignUp}>
          <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography align="center" variant="h5">
                Sign Up
              </Typography>
              <Typography align="center" variant="subtitle1">
                and gain moderator powers
              </Typography>
            </Grid>
            <Grid item>
              <EmailField disabled={isLoading} onChange={(event) => handleOnChangeEmail(event)} onValidationChanged={(isValid) => setIsEmailValid(isValid)} validationMode={inputValidationMode} validationType={EmailValidationType.SignUp} label="Please enter business email" />
            </Grid>
            <Grid item>
              <PasswordField disabled={isLoading} onChange={(event) => handleOnChangePassword(event)} onValidationChanged={(isValid) => setIsPasswordValid(isValid)} validationMode={inputValidationMode} />   
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.link}>
                {" "}
                by signing up to our 30 days free trial you agree to our{" "}
                <Link underline="always" color="inherit" target="_blank" href="https://storypoint.poker/story-point-poker-privacy-policy.pdf">
                  privacy policy
                </Link>{" "}
                and{" "}
                <Link underline="always" color="inherit" target="_blank" href="https://storypoint.poker/story-point-poker-terms-of-service.pdf">
                  terms of service
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <LoadingButton type="submit" isLoading={isLoading}>Sign Up</LoadingButton>
            </Grid>
            <Grid>
              <Typography variant="caption">or</Typography>
            </Grid>
            <Grid container spacing={0} direction="column" alignItems="center">
              <Grid item>
                <WindowsButton disabled={isLoading} onClick={(event) => handleProviderSignUp(event, AuthProviderType.Windows)} />
              </Grid>
              <Grid item>
                <GoogleButton disabled={isLoading} onClick={(event) => handleProviderSignUp(event, AuthProviderType.Google)} />
              </Grid>
            </Grid>
          </Grid>
        </form>
      ) : (
        <></>
      )}
    </>
  );
}
