import React, { ReactElement, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { DialogTransition } from "../components/DialogTransition";
import CloseIcon from "@mui/icons-material/Close";

export interface CustomDialogrops {
    open: boolean;
    onClosed: () => void;
}

export function CustomDialog(props: React.PropsWithChildren<CustomDialogrops>): ReactElement {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = React.useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleTransitionEnd = () => {
        if (!open) {
            props.onClosed();
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="custom-dialog-title"
            open={open}
            fullScreen={fullScreen}
            TransitionComponent={DialogTransition}
            keepMounted
            onTransitionEnd={handleTransitionEnd}
        >
            <DialogContent>
                <IconButton
                    style={{ position: "absolute", right: theme.spacing(1), top: theme.spacing(1), color: theme.palette.grey[500] }}
                    aria-label="close"
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid item>{props.children}</Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
