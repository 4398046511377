import { Button, CircularProgress, Theme } from '@mui/material';
import React, { ReactElement, SyntheticEvent, useContext } from 'react';
import { UserStatus } from '../api/models/User';
import { UserContext } from '../contexts/UserContext';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
        circularProgress: {
            color: theme.palette.primary.contrastText,
        },
    }),
);

export enum ButtonType {Standard, Submit}
interface SubmitButtonProps {
    type?: 'submit' | 'reset' | 'button' | undefined;
    isLoading: boolean
    onClick?: (event: SyntheticEvent) => void;
}

export function LoadingButton(props: React.PropsWithChildren<SubmitButtonProps>): ReactElement {
    const classes = useStyles();
    const {user} = useContext(UserContext);
    
    return (
        <Button className={classes.button} type={props.type} variant="contained" size="large" color="secondary" disabled ={props.isLoading || (user?.status == UserStatus.Suspended)} onClick={props.onClick}>
            {props.isLoading ? <CircularProgress className={classes.circularProgress} size={20} /> : props.children}
        </Button>
    );
}
