export enum PlanType {Free, Trial, Premium}
export enum PlanStatus {Active = 0, Canceled = 1, Expired = 2}
export enum UserStatus {Active, Suspended}

export interface Subscription {
  customerId: string,
  subscriptionId: string
}


export interface Plan {
  type: PlanType,
  status: PlanStatus,
  startDate: Date,
  endDate: Date,
  daysLeft: number,
  subscription: Subscription,
}

export interface User {
  id: string,
  idPId: string,
  email: string,
  plan: Plan,
  status: UserStatus,
  createdAt: Date,
  updateddAt: Date,
}