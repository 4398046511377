import React, { useState, SyntheticEvent, ReactElement } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useAlert } from "../contexts/alert/useAlert";
import { LoadingButton } from "../components/LoadingButton";
import { EmailValidationType, InputValidatorMode, useEmailValidator } from "../contexts/useInputValidator";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Action, Category, Tracking } from "../util/Tracking";
import { TrackChangesOutlined } from "@mui/icons-material";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
  })
);

export interface ResetPasswordProps {
  email: string;
  onResetPasswordCompleted?: () => void;
}

export default function ResetPassword(props: ResetPasswordProps): ReactElement {
  const track = new Tracking();
  const [validateEmail] = useEmailValidator(EmailValidationType.Other);
  const [email, setEmail] = useState(props.email);
  const [emailHelperText, setEmailHelperText] = useState("");
  const classes = useStyles();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  function validateInputEmail(inputEmail: string, mode: InputValidatorMode) {
    const res = validateEmail(inputEmail, mode);
    setEmailHelperText(res.errorMessage);
    return res.isValid;
  }

  function handleOnChangeEmail(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setEmail(event.target.value);
    validateInputEmail(event.target.value, InputValidatorMode.onTyping);
  }

  function onResetPasswordStarted() {
    const auth = getAuth();
    setIsLoading(true);
    return auth;
  }

  function onResetPasswordCompleted() {
    track.event(Category.User, Action.ResetPasswordRequestSent);
    alert.showMessage("Reset password email sent, please visit your inbox 👍", "success");
    if (props.onResetPasswordCompleted) {
      props.onResetPasswordCompleted();
    }
    setIsLoading(false);
  }

  function onResetPasswordFailed(error?: any) {
    setIsLoading(false);
    if (error != null) {
      const errorMessage = "Sorry, but were not able to send a reset password email, please try again 🤞";
      //console.error("errorcode: " + error.code + ", errorMessage: '" + error.message + "'");
      alert.showMessage(errorMessage, "error");
    }
  }

  function handleResetPassword(event: SyntheticEvent) {
    event.preventDefault();

    if (!validateInputEmail(email, InputValidatorMode.onSubmit)) {
      return;
    }

    const auth = onResetPasswordStarted();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        onResetPasswordCompleted();
      })
      .catch((error) => {
        onResetPasswordFailed(error);
      });
  }

  return (
    <form onSubmit={handleResetPassword}>
      <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography align="center" variant="h5">
            Reset Password
          </Typography>
          <Typography align="center" variant="subtitle1">
            and regain access
          </Typography>
        </Grid>

        <Grid item>
          <TextField value={email} error={emailHelperText.length > 0} helperText={emailHelperText} disabled={isLoading} onChange={(event) => handleOnChangeEmail(event)} id="outlined-basic" label="Please enter email" variant="outlined" color="secondary" />
        </Grid>

        <Grid item>
          <LoadingButton type="submit" isLoading={isLoading}>Reset Password</LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
