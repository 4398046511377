import React, { ReactElement, useContext, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Button, Link, Theme, Typography } from "@mui/material";
import { SignUpDialog } from "./SignUpDialog";
import { PlayerContext } from "../contexts/PlayerContext";
import { WindowGridContainer } from "../components/WindowGridContainer";
import { Action, Category, Tracking } from "../util/Tracking";
import { AuthState, UserContext } from "../contexts/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    contentItem: {
      margin: theme.spacing(1),
    },
  })
);

export interface SignUpTeaserProps {
  roomUrlId: string;
  onClose?: () => void;
}

export default function SignUpTeaser(props: SignUpTeaserProps): ReactElement {
  const track = new Tracking();
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { player: user } = useContext(PlayerContext);
  const { authState } = useContext(UserContext);
  const [ isCongratsMessageVisible, setIsCongratsMessageVisible] = useState(false);

  function handleClose() {
    setIsCongratsMessageVisible(false);

    if (props.onClose != null) {
      props.onClose();
    }
  }

  function handleSignUpClicked() {
    track.event(Category.User, Action.SignUpOpened, "signup teaser");
    setIsDialogOpen(true);
  }

  return isCongratsMessageVisible ? (
    <WindowGridContainer title="Congrats 🎉">
      <Grid item className={classes.contentItem}>
        <Typography color="inherit" variant="body2" align="center">
          Your moderator powers has increased 🥳
          <li>moderator insights 🧐</li>
          <li>unlimited players 🚀</li>
          <li>more card decks to choose from 🃏</li>
          <li>reuse the room link in recurring calendar invites 🗓️</li>
          <li>kick other players 🦿</li>
        </Typography>
      </Grid>
      <Grid item>
        <Button className={classes.button} onClick={handleClose} variant="contained" size="small" color="primary">
          Got It
        </Button>
      </Grid>
    </WindowGridContainer>
  ) : user?.isModerator && (authState == AuthState.SignedOut || authState == AuthState.Authenticating) ? (
    <WindowGridContainer title="Sign Up">
      <Grid item className={classes.contentItem}>
        <Typography color="inherit" variant="body2" align="center">
          Gain moderator powers by signing up for a <i>30 days free trial</i> of the <Link underline="always" target="_blank" href="https://storypoint.poker/#prices" color="inherit">Business Subscription</Link>. It only takes a few seconds and can be done while waiting for your team mates to join 🚀
        </Typography>
      </Grid>
      <Grid item>
        <Button className={classes.button} onClick={handleSignUpClicked} variant="contained" size="small" color="secondary">
          Sign Up Now
        </Button>
      </Grid>
      {isDialogOpen && <SignUpDialog open={isDialogOpen} onClosed={() => setIsDialogOpen(false)} onCompleted={() => setIsCongratsMessageVisible(true)} />}
    </WindowGridContainer>
  ) : (
    <></>
  );
}
