import React, { ReactElement } from "react";
import { CardDeckSettings } from "./CardDeckSettings";
import { CustomDialog } from "../components/CustomDialog";

export interface CardDeckSettingsDialogProps {
  open: boolean;
  onClosed: () => void;
}

export function CardDeckSettingsDialog(props: CardDeckSettingsDialogProps): ReactElement {
  const [open, setOpen] = React.useState(props.open);

  const handleClosed = () => {
    props.onClosed();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomDialog open={open} onClosed={handleClosed}>
      <CardDeckSettings onSaved={handleClose} />
    </CustomDialog>
  );
}
