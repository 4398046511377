import React, { ReactElement, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { GameState } from './Room';
import { UserContext } from '../contexts/UserContext';
import { UserStatus } from '../api/models/User';

interface ModerationPanelProps {
    gameState: GameState;
    isRevealEnabled: boolean;
    onStarted?: () => void;
    onStopped?: () => void;
}

export default function ModerationPanel(props: ModerationPanelProps): ReactElement {
 
    const { user } = useContext(UserContext);

    function handleButtonClick() {
        if ((props.gameState === GameState.WaitingForPLayers) || (props.gameState === GameState.Result)) {
            if (props.onStarted != null) {
                props.onStarted();
            }
        } else if (props.gameState === GameState.Playing) {
            if (props.onStopped) {
                props.onStopped();
            }
        }
    }

    function buttonTitle(gameState: GameState) {
        switch (gameState) {
            case GameState.WaitingForPLayers:
                return "Start Estimating"
            case GameState.Playing:
                return "Reveal Estimates"
            case GameState.Result:
                return "Start New Estimation Round"
            default:
                return "?"
        }
    }

    return (
        <Grid container spacing={3} direction="column" justifyContent="space-between" alignItems="center">
            <Grid item><Button disabled={user?.status == UserStatus.Suspended || (props.gameState === GameState.Playing && !props.isRevealEnabled)} onClick={handleButtonClick} variant="contained" size="large" color="secondary">{buttonTitle(props.gameState)}</Button></Grid>
        </Grid>
    );

}
