export class InputValidationResult {
  isValid: boolean;
  errorMessage: string;

  constructor(isValid: boolean, errorMessage: string) {
    this.isValid = isValid;
    this.errorMessage = !isValid ? errorMessage : "";
  }
}

export enum InputValidatorMode {
  onSubmit,
  onTyping,
}

export function useUserNameValidator() {
  function validateUserName(value: string, mode: InputValidatorMode): InputValidationResult {
    if (mode == InputValidatorMode.onSubmit) {
      return new InputValidationResult(value.length >= 1 && value.length <= 30, "please stay within 1-30 characters 🙏");
    } else {
      return new InputValidationResult(value.length <= 30, "no more than 30 characters please 🙏");
    }
  }

  return [validateUserName];
}
export enum EmailValidationType {SignUp, Other}

export function useEmailValidator(type: EmailValidationType) {
  function validateEmail(value: string, mode: InputValidatorMode): InputValidationResult {
    if (mode === InputValidatorMode.onSubmit) {
      return new InputValidationResult(value.includes("@"), "please enter a valid email 🙏");
    } else {
      return type == EmailValidationType.SignUp ? new InputValidationResult(!value.includes("gmail"), "please use your business email 🙏") :
      new InputValidationResult(value.length <= 255, "no more than 255 characters please 🙏");
    }
  }

  return [validateEmail];
}

export function usePasswordValidator() {
  function validatePassword(value: string, mode: InputValidatorMode): InputValidationResult {
    if (mode === InputValidatorMode.onSubmit) {
      return new InputValidationResult(value.length >= 6, "please provide a strong password 🙏");
    } else {
      return new InputValidationResult(value.length <= 255, "no more than 255 characters please 🙏");
    }
  }

  return [validatePassword];
}
