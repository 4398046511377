import { TextField } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { InputValidatorMode, usePasswordValidator } from '../contexts/useInputValidator';


interface PasswordFieldProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onValidationChanged: (isValid: boolean) => void;
    validationMode: InputValidatorMode;
    disabled: boolean;
}

export function PasswordField(props: PasswordFieldProps): ReactElement {
    const [passwordHelperText, setPasswordHelperText] = useState("");
    const [validatePassword] = usePasswordValidator();
    const [password , setPassword] = useState("");

    useEffect(()=>{
        if (props.validationMode == InputValidatorMode.onSubmit) {
            validateInputPassword(password, props.validationMode);
        }

    },[props.validationMode]);

    function validateInputPassword(inputPassword: string, mode: InputValidatorMode) {
        const res = validatePassword(inputPassword, mode);
        setPasswordHelperText(res.errorMessage);
        return res.isValid;
    }
 
    function handleOnChangePassword(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setPassword(event.target.value);
        const res = validatePassword(event.target.value, InputValidatorMode.onSubmit);
        props.onValidationChanged(res.isValid);
        validateInputPassword(event.target.value, InputValidatorMode.onTyping);
        props.onChange(event);
      }

    return (
        <TextField
        type="password"
        error={passwordHelperText.length > 0}
        helperText={passwordHelperText}
        disabled={props.disabled}
        onChange={handleOnChangePassword}
        id="password-field"
        label="Please enter password"
        variant="outlined"
        color="secondary"
      />
    );
}
