import React, { useContext, useState } from "react";
import { ReactElement, useEffect, useRef } from "react";
import { UserContext } from "../contexts/UserContext";
import { Button, Grid, Link, Theme, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "../components/LoadingButton";
import { Action, Category, Tracking } from "../util/Tracking";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAlert } from "../contexts/alert/useAlert";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    text: {
        maxWidth: 400,
        textAlign: "center",
      },
    link: {
      maxWidth: 400,
      fontStyle: "italic",
      textAlign: "center",
    },
  })
);

interface SubscribeProps {
    onCompleted?: () => void;
  }

export function Subscribe(props: SubscribeProps): ReactElement {
  const { user, authToken } = useContext(UserContext);
  const formRef = useRef<HTMLFormElement>(null);
  const productId = process.env.REACT_APP_PREMIUM_SUBSCRIPTION_PRODUCT_ID;
  const paymentLinkBaseUrl = process.env.REACT_APP_PREMIUM_SUBSCRIPTION_PAYMENT_LINK;
  const promotionCode = process.env.REACT_APP_PREMIUM_SUBSCRIPTION_PROMOTION_CODE;
  const [stripePaymentLink, setStripePaymentLink] = useState("");
  const track = new Tracking();
  const alert = useAlert();
  const classes = useStyles();

  useEffect(() => {
    if (user) {
      formRef.current && formRef.current.submit();
      let paymnetLink = paymentLinkBaseUrl + "?client_reference_id=" + user.idPId + "&prefilled_email=" + user.email;

      if (promotionCode) {
        paymnetLink += "&prefilled_promo_code=" + promotionCode;
      }

      setStripePaymentLink(paymnetLink);
    }
  }, [user, formRef]);

  return user && !user?.plan?.subscription ? (
    <>
      <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography align="center" variant="h5">
            Subscribe Now
          </Typography>
          <Typography align="center" variant="subtitle1"> 
            and keep your moderator powers
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.text}>Thank you for considering the <Link underline="always" target="_blank" href="https://storypoint.poker/#prices" color="inherit">Business Subscription</Link>. If you are the one in your organisation who can also pay for the subscription start the checkout flow now.</Typography>
        </Grid>
        <Grid item>
          <form action="api/create-checkout-session" method="POST">
            {/* Add a hidden field with the lookup_key of your Price */}
            <input type="hidden" name="price_id" value={productId} />
            <input type="hidden" name="jwt" value={authToken} />
            <input type="hidden" name="return_url" value={window.location.href} />
            <Button variant="contained" size="large" color="secondary" type="submit" onClick={() => {track.event(Category.User, Action.PurchaseStarted, user?.idPId)}}>
              Pay Now
            </Button>
          </form>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.text}>If there is another friendly soul in your organisation who would be so kind to pay for the subscription you can create a payment link and send it to them.</Typography>
        </Grid>
        <Grid item>
          <CopyToClipboard
            text={stripePaymentLink}
            onCopy={() => {track.event(Category.User, Action.PurchaseLinkCreated, user.idPId); alert.showMessage("Your Payment link is now copied to the clipboard. Shared it with your boss or another friendly soul in your organization who would be so kind to pay 💸", "success")}}
          >
            <Tooltip title="A payment link can be shared with your boss or another friendly soul in your organization 💸" aria-label="Copy Room Link">
              <Button disabled={user.id == null || user.email == null} variant="outlined" size="large" color="secondary">Create Payment Link</Button>
            </Tooltip>
          </CopyToClipboard>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.link}>
            The subscription can be managed from within the app and you are able to cancel it at any time. If you have any questions regarding the subscription checkout our{" "}
            <Link underline="always" color="inherit" target="_blank" href="https://storypoint.poker/story-point-poker-terms-of-service.pdf">
              terms of service
            </Link>{" "}
            or contact us at{" "}
            <Link underline="always" color="inherit" href="mailto:support@storypoint.poker">
              support@storypoint.poker
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
}
