import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { HttpClient } from "../api/HttpClient";
import { User } from "../api/models/User";
import { AuthState, UserContext } from "../contexts/UserContext";
import { useEnvironment } from "../hooks/useEnvironment";
import { firebaseConfigDev, firebaseConfigProd } from "./firebaseConfig";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AuthProviderProps {}

export function AuthProvider(props: React.PropsWithChildren<AuthProviderProps>): ReactElement {
  const [authToken, setAuthToken] = useState<string>();
  const [user, setUser] = useState<User>();
  const [authState, setAuthState] = useState<AuthState>(AuthState.Initializing);
  const [isDevelopment] = useEnvironment();

  //initializes authentication on reload
  useEffect(() => {
    const initializeAuthentication = async () => {
      //console.log("initializing auth state...");
      const firebaseConfig = isDevelopment() ? firebaseConfigDev : firebaseConfigProd;
      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);

      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          const jwt = await user.getIdToken();
          try {
            const httpClient = new HttpClient(jwt);
            const user = (await httpClient.post("/users/signin")) as User;

            setAuthToken(jwt);
            setUser(user);
            setAuthState(AuthState.SignedIn);
            //console.log("user is signed in");
          } catch (e) {
            console.error("unexpected error occured during auto sign in: " + e);
          }
        } else {
          setAuthToken(undefined);
          setUser(undefined);
          setAuthState(AuthState.SignedOut);
          //console.log("user is signed out");
        }
      });
      unsubscribe();
    };
    initializeAuthentication();
  }, []);

  return <UserContext.Provider value={{ authToken: authToken, setAuthToken: setAuthToken, authState: authState, setAuthState: setAuthState, user: user, setUser: setUser }}>{props.children}</UserContext.Provider>;
}
